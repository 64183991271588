<template>
<div class="galleryBg" v-if="!isMobile()">
    <Sidebar/>
    <Navbar/>

    <div class="maskOneGallery"></div>
    <div class="maskTwoGallery"></div>

    <div class="galleryInfoBg"></div>

    <h2 class="galleryInfoTitle">GALLERY</h2>
    <img class="barsGallery" src="../assets/MintPage/bars.png" alt="">

    <p class="galleryPara">
        Welcome to the Project Cyber gallery page, where we showcase our unique collection of Anime and Sci-fi style NFT art, featuring high quality art and animations that have taken us over 6 months to create.
        <br><br>
        We invite you to immerse yourself in our collection, and explore the world of Project Cyber through our stunning NFT art and animations. Join us on this exciting journey as we bring the future to life through the power of art.
    </p>

    <img class="leftLogo" src="../assets/logo.png" alt="">
    <img class="rightLogo" src="../assets/HeroPage/logo.png" alt="">


    <img class="CY" src="../assets/GalleryPage/CY.png" alt="">

</div>


</template>

<script>
import Sidebar from './Navigation/Sidebar.vue'
import Navbar from './Navigation/Navbar.vue'


export default {
    components:{
        Sidebar,
        Navbar,
    },
    methods:{
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
    }
}
</script>

<style>
.galleryBg
{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: url('../assets/WhitelistPage/bgLayer.png'), url('../assets/WhitelistPage/bg.png') no-repeat center top;
    background-size: 100% 100%;
    min-width: 1250px !important;
    min-height: 800px !important;
    z-index: 1 !important;
}
.maskOneGallery
{
    position: absolute;
    top: 15%;
    left: 10%;
    width: 32%;
    height: 55%;

    background-color: #ffc100 !important;
    background: url('../assets/GalleryPage/machine.png');
    background-repeat: no-repeat;
    background-size: 120% 120%;
    background-position: 50%;

    
    -webkit-mask: url("../assets/GalleryPage/firstMask.png");
    mask: url("../assets/GalleryPage/firstMask.png");

    -webkit-mask-size:  60% 70%;
    mask-size:  60% 70%;

    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
}

.maskTwoGallery
{
    position: absolute;
    top: 17%;
    left: 32%;
    width: 325px;
    height: 37%;

    background-color: aqua !important;
    background: url('../assets/GalleryPage/galleryfull.jpg');
    background-repeat: no-repeat;
    background-size: 100% 110%;
    background-position: center;

    -webkit-mask: url("../assets/GalleryPage/secondMask.png");
    mask: url("../assets/GalleryPage/secondMask.png");

    -webkit-mask-size:  100% 100%;
    mask-size:  100% 100%;

    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
}
.galleryInfoBg
{
    position: absolute;
    top: 60%;
    left: 10%;
    width: 670px;
    height: 250px;


    padding: 50px;
    background: url('../assets/GalleryPage/galleryInfoBg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.galleryInfoTitle
{
    position: absolute;
    top: 54%;
    left: 12%;
    font-size: 7.5vh;
    font-weight: 500;
}
.barsGallery
{
    position: absolute;
    top: 64.5%;
}
.galleryPara
{
    position: absolute;
    width: 63vh;
    font-family: 'Antonio', sans-serif;
    top: 68%;
    left: 12%;
    word-wrap: normal !important;
    text-align: justify;
}
.squareImage
{
    position: absolute;
    top: 17%;
    left: 50%;
    width: 200px;
    height: 200px;

    background: url('../assets/GalleryPage/iso.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: cover;

    -webkit-mask: url("../assets/GalleryPage/thirdMask.png");
    mask: url("../assets/GalleryPage/thirdMask.png");

    -webkit-mask-size:  100% 100%;
    mask-size:  100% 100%;

    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
}

.CY
{
    position: absolute;
    top: 60%;
    width: 85vh;
}

.clothingImageGallery
{
    position: absolute;
    top: 37%;
    left: 50%;
    width: 200px;
    height: 185px;
    
    background: #ebebeb url('../assets/GalleryPage/jacket.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    

    -webkit-mask: url("../assets/GalleryPage/fourthMask.png");
    mask: url("../assets/GalleryPage/fourthMask.png");

    -webkit-mask-size:  100% 100%;
    mask-size:  100% 100%;

    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
}
.leftLogo
{
    position: absolute;
    top: 82%;
    left: 12%;
    width: 5vh;
}
.rightLogo
{
    position: absolute;
    top: 82%;
    left: 40%;
    width: 12vh;
}

@media only screen and (min-width: 1800px)
{
    .maskOneGallery
    {
        width: 26%
    }
    .maskTwoGallery
    {
        left: 28%;
        width: 400px;
    }
}
@media only screen and (max-width: 2000px), screen and (max-height:1200px) 
{
    .galleryInfoBg
    {
        width: 735px;
    }
    .barsGallery
    {
        left: 35%;
    }
    .squareImage
    {
        left: 46%;
    }
    .CY
    {
        width: 78vh;
    }
    .clothingImageGallery
    {
        top: 37%;
        left: 46%;
        width: 200px;
        height: 200px;
    }
    .leftLogo
    {
        top: 82%;
    }
    .rightLogo
    {
        top: 82%;
        left: 38%;
    }
}
@media only screen and (max-width: 1920px), screen and (max-height: 1000px)
{
    .galleryInfoBg
    {
        width: 645px;
    }
    .barsGallery
    {
        left: 38%;
    }
    .squareImage
    {
        left: 50%;
    }
    .CY
    {
        width: 85vh;
    }
    .clothingImageGallery
    {
        top: 37%;
        left: 50%;
        width: 200px;
        height: 185px;
    }
    .leftLogo,.rightLogo
    {
        top: 85%;
    }
    .rightLogo
    {
        left: 40%;
    }
    
    
}

@media only screen and (max-width: 1800px) 
{
    .squareImage
    {
        left: 52%;
        width: 155px;
        height: 170px;
    }
    .clothingImageGallery
    {
        left: 52%;
        width: 155px;
        height: 160px;
    }
    .galleryInfoBg
    {
        width: 620px;
    }
    .CY
    {
        top: 65%;
        right: 3%;
    }
    .leftLogo
    {
        top: 88%;
    }
    .rightLogo
    {
        top: 88%;
    }
}
@media only screen and (max-width: 1500px) 
{
    .galleryInfoBg
    {
        width: 545px;
        height: 200px;
    }
    .barsGallery
    {
        top: 64.5%;
        left: 40%;
    }
    .galleryPara
    {
        width: 66vh;
    }
    .squareImage
    {
        left: 56%;
        width: 155px;
        height: 155px;
    }
    .CY
    {
        top: 70%;
        width: 60vh;
        right: 5%;
    }
    .clothingImageGallery
    {
        top: 35.5%;
        left: 56%;
        width: 155px;
        height: 170px;
    }
    .leftLogo,.rightLogo
    {
        top: 87%;
    }
    .rightLogo
    {
        left: 45%;
    }
}
@media screen and (max-width: 1300px) 
{
    .galleryInfoBg
    {
        width: 505px;
    }
    .barsGallery
    {
        left: 45%;
    }
    .galleryPara
    {
        width: 60vh;
    }
    .squareImage
    {
        left: 59%;
    }
    .CY
    {
        top: 72.5%;
        width: 50vh;
        right: 2%;
    }
    .clothingImageGallery
    {
        top: 35.5%;
        left: 59%;
        width: 155px;
        height: 175.5px;
    }
    .leftLogo,.rightLogo
    {
        top: 86%;
    }
    .rightLogo
    {
        left: 47%;
    }
}
@media screen and (max-height: 800px) 
{
    .galleryInfoBg
    {
        height: 180px;
    }
    .barsGallery
    {
        top: 64%;
        left: 40%;
    }
    .galleryPara
    {
        width: 70vh;
    }
    .CY
    {
        top: 72%;
        width: 60vh;
        right: 2%;
    }
    .clothingImageGallery
    {
        top: 36%;
        left: 59%;
        width: 140px;
        height: 145px;
    }
    .squareImage
    {
        width: 140px;
        height: 140px;
    }
    .leftLogo,.rightLogo
    {
        top: 88.5%;
    }
    .rightLogo
    {
        left: 48%;
    }
}

</style>