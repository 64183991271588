<template>
<div class="plasmaContain">
    <h2 class="plasmaHeading">THE STORY</h2>
    <h2 class="vialsHeading">THE VIALS</h2>
    <img class="vialsMain" src="../../assets/PlasmaPage/vialsMain.png">
    <div id="charMain"><img id="charImg" src="../../assets/PlasmaPage/charMain.png"></div>


    <div id="pm" class="plasmaMask">
        
    </div>
    <h2 class="maskHeader">PLASMA</h2>
    <p class="plasmaText">
        A highly desired and valuable iridescent pink liquid that is created by condensing artificially-grown intelligence data. It is used to stabilize artificial intelligence consciousness in physical bodies, such as cyborgs, and to enhance their cybernetic abilities.
    </p>
    <p class="plasmaText2">
        However, the use of plasma must be carefully controlled and monitored, as excessive use can lead to instability and malfunction in the AI consciousness.
        Additionally, the process of creating plasma is complex and requires advanced technology and expertise, making it a scarce and expensive commodity. Despite these challenges, researchers and scientists continue to work on improving the production and use of plasma in order to unlock its full potential and create more advanced forms of AI and cyborgs.
    </p>

    <p class="vialText">
        The Genesis project is a secret project that aims to create an alternative to Plasma. The project was made public due to pressure from a cyber terrorist organization who hacked the corporation's computer systems. 
        <br><br>
        The corporation claims the new alternative will be easier to obtain and less expensive, making it more accessible to the public. However, the details of the project are heavily guarded by multiple layers of advanced security systems.
    </p>

    <p class="vialText2">
        CRO-CNI was created in the year 2039 by scientists from the Cyber Corporation. It is a metal container used to create a body for artificial intelligence, making it possible to create cyborgs. 
        <br><br>
        It's malleable at high temperatures and allows electrical stimulation to function like a nervous system. It's the only known way to create cyborgs with human-like autonomy, unlike traditional robots.
    </p>

    <h2 class="worldHeader">THE WORLD</h2>

    <p class="worldText">The key to the Cyberverse lies in your hands, will you be one of the few?</p>
    
</div>
</template>

<script>
export default {
    mounted()
    {
        (function() {
            var container = document.getElementById("charMain"),
                inner = document.getElementById("charImg");

            // Mouse
            var mouse = {
                _x: 0,
                _y: 0,
                x: 0,
                y: 0,
                updatePosition: function(event) {
                var e = event || window.event;
                this.x = e.clientX - this._x;
                this.y = (e.clientY - this._y) * -1;
                },
                setOrigin: function(e) {
                this._x = e.offsetLeft + Math.floor(e.offsetWidth / 2);
                this._y = e.offsetTop + Math.floor(e.offsetHeight / 2);
                },
                show: function() {
                return "(" + this.x + ", " + this.y + ")";
                }
            };

            // Track the mouse position relative to the center of the container.
            mouse.setOrigin(container);

            //----------------------------------------------------

            var counter = 0;
            var refreshRate = 10;
            var isTimeToUpdate = function() {
                return counter++ % refreshRate === 0;
            };

            //----------------------------------------------------

            var onMouseEnterHandler = function(event) {
                update(event);
            };

            var onMouseLeaveHandler = function() {
                inner.style = "";
            };

            var onMouseMoveHandler = function(event) {
                if (isTimeToUpdate()) {
                update(event);
                }
            };

            //----------------------------------------------------

            var update = function(event) {
                mouse.updatePosition(event);
                updateTransformStyle(
                (mouse.y / inner.offsetHeight / 2).toFixed(2),
                (mouse.x / inner.offsetWidth / 2).toFixed(2)
                );
            };

            var updateTransformStyle = function(x, y) {
                var style = "rotateX(" + x + "deg) rotateY(" + y + "deg)";
                inner.style.transform = style;
                inner.style.webkitTransform = style;
                inner.style.mozTranform = style;
                inner.style.msTransform = style;
                inner.style.oTransform = style;
            };

            //--------------------------------------------------------

            container.onmousemove = onMouseMoveHandler;
            container.onmouseleave = onMouseLeaveHandler;
            container.onmouseenter = onMouseEnterHandler;
            })();
    }
}
</script>

<style>
.plasmaContain
{
  position: absolute;
  top:100%;
  left:0;
  width: 100%;
  height: 100%;
  background: url('../../assets/PlasmaPage/plasmaBG.png') no-repeat center top;
  background-size: 100% 100%;
  min-width: 1250px !important;
  min-height: 800px !important;
  z-index: 1 !important;
}
.plasmaHeading
{
    z-index: 3 !important;
    position: absolute;
    top: 7%;
    left: 27%;
    font-size: 2vh;
    font-weight: 100;
}
.vialsHeading
{
    z-index: 3 !important;
    position: absolute;
    top: 16.2%;
    left: 59%;
    font-size: 1vw;
    font-weight: 100;
}
.vialsMain
{
    z-index: 2 !important;
    position: absolute;
    top: 15%;
    left: 53%;
    width: 20vw;
}
#charImg
{
    z-index: 2 !important;
    width: 100%;
    transition: transform 0.5s;
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -o-transition: transform 0.5s;
}
#charMain
{
    position: absolute;
    top: 18%;
    left: 76%;
    width: 18vw;
    perspective: 25px;
}
.maskHeader
{
    position: absolute;
    display: flex;
    z-index: 2;
    font-size: 4vh;
    top: 64.5%;
    left: 31%;
}
.plasmaText
{
    position: absolute;
    display: flex;
    z-index: 2;
    font-size: 1.5vh;
    width: 34vh;
    text-align: justify;
    top: 71%;
    left: 31%;
    font-family: 'Antonio', sans-serif;
}
.plasmaText2
{
    position: absolute;
    display: flex;
    z-index: 2;
    font-size: 1.5vh;
    width: 75vh;
    text-align: justify;
    top: 84%;
    left: 8%;
    font-family: 'Antonio', sans-serif;
}

.vialText
{
    position: absolute;
    display: flex;
    z-index: 2;
    font-size: 1.5vh;
    width: 36vh;
    text-align: justify;
    top: 46%;
    left: 55%;
    font-family: 'Antonio', sans-serif;
}
.vialText2
{
    position: absolute;
    display: flex;
    z-index: 2;
    font-size: 1.5vh;
    width: 36vh;
    text-align: justify;
    top: 46%;
    left: 76%;
    font-family: 'Antonio', sans-serif;
}

.worldHeader
{
    position: absolute;
    display: flex;
    z-index: 2;
    width: 50vh;
    font-size: 4vh;
    font-weight: 100;
    top: 64.5%;
    left: 78%;
}
.worldText
{
    position: absolute;
    display: flex;
    z-index: 2;
    width: 50vh;
    font-size: 1.45vh;
    font-weight: 100;
    top: 87%;
    left: 78%;
    font-family: 'Antonio', sans-serif;
}

.plasmaMask
{
    z-index: 2 !important;
    position: absolute;
    background: url('../../assets/PlasmaPage/particles.png'),url('../../assets/PlasmaPage/char.png'),url('../../assets/PlasmaPage/plant.png'),url('../../assets/PlasmaPage/desk.png'), url('../../assets/PlasmaPage/cables.png'), url('../../assets/PlasmaPage/wall.png'),url('../../assets/PlasmaPage/maskbg3.png'), url('../../assets/PlasmaPage/maskbg2.png'), url('../../assets/PlasmaPage/maskbg.png');
    background-size: 100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 100%;
    background-position-x: 0, 0, 0, 0, 0, 0, 0, 0, 0;
    background-repeat: no-repeat,no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    width: 55%;
    height: 67%;
    top: 16%;
    left: 0%;
    overflow: hidden;

    -webkit-mask: url("../../assets/PlasmaPage/mask.png");
    mask: url("../../assets/PlasmaPage/mask.png");

    -webkit-mask-size:100% 100%;
    mask-size:100% 100%;

    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    

    
}


@media only screen and (max-width: 1780px) {
    .plasmaText
    {
        width: 30vh !important;
    }
    .plasmaText2
    {
        width: 66vh !important;
    }
    .worldText
    {
        font-size: 1.5vh;
    }

}

@media only screen and (max-width: 1500px) {
    .plasmaText
    {
        font-size: 1.5vh !important;
        width: 28vh !important;
    }
    .plasmaText2
    {
        top: 85.5%;
        font-size: 1.5vh !important;
        width: 64vh !important;
    }

    .vialText, .vialText2
    {
        width: 32vh;
        top: 42%;
    }
}

@media only screen and (max-width: 1300px) {
    .plasmaText
    {
        font-size: 1.4vh !important;
        width: 26vh !important;
    }
    .plasmaText2
    {
        top: 85%;
        font-size: 1.4vh !important;
        width: 57vh !important;
    }

    .vialText, .vialText2
    {
        width: 27.5vh;
        top: 40%;
    }

    .worldText
    {
        font-size: 1.3vh;
        left: 77.5%;
    }
    .worldHeader
    {
        left: 76%;
    }
    
}


</style>