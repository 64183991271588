<template>
<div id="NavBar">
    <div class="HoverOver" @click="routeTo('Home')">
        <h2 class="homeNav" >home</h2>
        <img class="activeBar" src="../../assets/NavBar/active.png" alt="">
    </div>

    <el-divider class="divider" direction="vertical" />

    <div class="HoverOver" @click="routeTo('Gallery')">
        <h2 class="homeNav">gallery</h2>
        <img class="activeBar" src="../../assets/NavBar/active.png" alt="">
    </div>

<!--     <el-divider class="divider" direction="vertical" />

    <div class="HoverOver" @click="routeTo('About')">
        <h2 class="homeNav">about us</h2>
        <img class="activeBar" src="../../assets/NavBar/active.png" alt="">
    </div> -->

    <el-divider class="divider" direction="vertical" />

    <!-- @click="routeTo('Mint')" -->
    <div class="HoverOver" >
        <h2 class="homeNav grey">mint</h2>
        <img class="activeBar" src="../../assets/NavBar/active.png" alt="">
    </div>

</div>

<img class="lines2" src="../../assets/NavBar/lines2.png" alt="">
<h2 id="connectWallet" class="connectButton" v-if="walletAddress.length < 5" @click="alertSoon()">CONNECT</h2>
<h2 id="connectWallet2" class="connectButton" v-if="walletAddress.length > 5" >{{walletAddress.substring(0,3)}}...{{walletAddress.substring(39,42)}}</h2>

</template>

<script>
import Web3 from 'web3';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

const providerOptions = {
    walletconnect: {
        package: WalletConnectProvider, // required
        options: {
            infuraId: "935fc1398c7946b8a7ded7d0981fda7a", // required
        }
    }
}

export default {
    data()
    {
        return{
            active: -1,

            web3: '',
            web3ModalInstance: '',
            walletAddress: '',
            walletBalance: '',
            web3Modal: '',
        }
    },
    mounted(){
        if(sessionStorage.getItem("walletAddress") !== null)
        {
            this.walletAddress = sessionStorage.getItem("walletAddress")
        }
        this.web3Modal = new Web3Modal({
                network: "mainnet",
                cacheProvider: true,
                providerOptions,
        })
        this.clearCache(); 


        var navLinks = document.querySelectorAll(".activeBar")

        for(var i=0; i<navLinks.length; i++)
        {
            navLinks[i].classList.remove("isActive")
        }

        if(this.$route.name === "Home")
        {
            navLinks[0].classList.add("isActive")
        }
        else if(this.$route.name === "About")
        {
            navLinks[2].classList.add("isActive")
        }
        else if(this.$route.name === "Gallery")
        {
            navLinks[1].classList.add("isActive")
        }
        else if(this.$route.name === "Mint")
        {
            navLinks[2].classList.add("isActive")
        }

        function rand(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }

        function getRandomLetter() {
            var alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
            return alphabet[rand(0, alphabet.length - 1)]
        }

        function getRandomWord(word) {
            var text = word.innerText

            var finalWord = ''
            for (var i = 0; i < text.length; i++) {
                finalWord += text[i] == ' ' ? ' ' : getRandomLetter()
            }

            return finalWord
        }

        var word = document.querySelectorAll('.homeNav')
        var interv = 'undefined'
        var interv2 = 'undefined'
        var interv3 = 'undefined'
        //var interv4 = 'undefined'
        //var interv5 = 'undefined'

        var canChange = false
        var canChange2 = false
        var canChange3 = false
        //var canChange4 = false
        //var canChange5 = false


        var globalCount = 0
        var count = 0
        var INITIAL_WORD = word[0].innerText;
        var INITIAL_WORD1 = word[1].innerText;
        var INITIAL_WORD2 = word[2].innerText;
/*         var INITIAL_WORD3 = word[3].innerText;
        var INITIAL_WORD4 = word[4].innerText; */

        var isGoing = false
        var isGoing2 = false
        var isGoing3 = false
        //var isGoing4 = false
        //var isGoing5 = false

        function init() {
            if (isGoing) return;

            isGoing = true
            var randomWord = getRandomWord(word[0])
            word[0].innerText = randomWord

            interv = setInterval(function() {
                var finalWord = ''
                for (var x = 0; x < INITIAL_WORD.length; x++) {
                    if (x <= count && canChange) {
                        finalWord += INITIAL_WORD[x]
                    } else {
                        finalWord += getRandomLetter()
                    }
                }
                word[0].innerText = finalWord
                if (canChange) {
                    count++
                }
                if (globalCount >= 20) {
                    canChange = true
                }
                if (count >= INITIAL_WORD.length) {
                    clearInterval(interv)
                    count = 0
                    canChange = false
                    globalCount = 0
                    isGoing = false
                }
                globalCount++
            }, 20)

        }

        function init1() {
            if (isGoing2) return;

            isGoing2 = true
            var randomWord = getRandomWord(word[1])
            word[1].innerText = randomWord

            interv2 = setInterval(function() {
                var finalWord = ''
                for (var x = 0; x < INITIAL_WORD1.length; x++) {
                    if (x <= count && canChange2) {
                        finalWord += INITIAL_WORD1[x]
                    } else {
                        finalWord += getRandomLetter()
                    }
                }
                word[1].innerText = finalWord
                if (canChange2) {
                    count++
                }
                if (globalCount >= 20) {
                    canChange2 = true
                }
                if (count >= INITIAL_WORD1.length) {
                    clearInterval(interv2)
                    count = 0
                    canChange2 = false
                    globalCount = 0
                    isGoing2 = false
                }
                globalCount++
            }, 20)
        }

        function init2() {
            if (isGoing3) return;

            isGoing3 = true
            var randomWord = getRandomWord(word[2])
            word[2].innerText = randomWord

            interv3 = setInterval(function() {
                var finalWord = ''
                for (var x = 0; x < INITIAL_WORD2.length; x++) {
                    if (x <= count && canChange3) {
                        finalWord += INITIAL_WORD2[x]
                    } else {
                        finalWord += getRandomLetter()
                    }
                }
                word[2].innerText = finalWord
                if (canChange3) {
                    count++
                }
                if (globalCount >= 20) {
                    canChange3 = true
                }
                if (count >= INITIAL_WORD2.length) {
                    clearInterval(interv3)
                    count = 0
                    canChange3 = false
                    globalCount = 0
                    isGoing3 = false
                }
                globalCount++
            }, 20)

        }

/*         function init3() {
            if (isGoing4) return;

            isGoing4 = true
            var randomWord = getRandomWord(word[3])
            word[3].innerText = randomWord

            interv4 = setInterval(function() {
                var finalWord = ''
                for (var x = 0; x < INITIAL_WORD3.length; x++) {
                    if (x <= count && canChange4) {
                        finalWord += INITIAL_WORD3[x]
                    } else {
                        finalWord += getRandomLetter()
                    }
                }
                word[3].innerText = finalWord
                if (canChange4) {
                    count++
                }
                if (globalCount >= 20) {
                    canChange4 = true
                }
                if (count >= INITIAL_WORD3.length) {
                    clearInterval(interv4)
                    count = 0
                    canChange4 = false
                    globalCount = 0
                    isGoing4 = false
                }
                globalCount++
            }, 20)

        }

        function init4() {
            if (isGoing5) return;

            isGoing5 = true
            var randomWord = getRandomWord(word[4])
            word[4].innerText = randomWord

            interv5 = setInterval(function() {
                var finalWord = ''
                for (var x = 0; x < INITIAL_WORD4.length; x++) {
                    if (x <= count && canChange5) {
                        finalWord += INITIAL_WORD4[x]
                    } else {
                        finalWord += getRandomLetter()
                    }
                }
                word[4].innerText = finalWord
                if (canChange5) {
                    count++
                }
                if (globalCount >= 20) {
                    canChange5 = true
                }
                if (count >= INITIAL_WORD4.length) {
                    clearInterval(interv5)
                    count = 0
                    canChange5 = false
                    globalCount = 0
                    isGoing5 = false
                }
                globalCount++
            }, 20)

        } */

        
        word[0].addEventListener('mouseenter', init)
        word[1].addEventListener('mouseenter', init1)
        word[2].addEventListener('mouseenter', init2)
        //word[3].addEventListener('mouseenter', init3)
        //word[4].addEventListener('mouseenter', init4)
    },
    methods:{
        alertSoon()
        {
            alert("Coming Soon")
        },
        routeTo(location)
        {
            this.$router.push({ name: location})
        },
        async connectWallet()
        {   
            this.web3ModalInstance = await this.web3Modal.connect()
            this.web3 = await new Web3(this.web3ModalInstance)
            this.web3.eth.getChainId()
            .then(res=>{
                console.log(res)
                if(res === 1)
                {
                    this.LoadEthWallet();
                }
                else
                {
                    this.chainChange();
                }
            })
        },
        async clearCache()
        {
            await this.web3Modal.clearCachedProvider();
        },
        async chainChange()
        {
            await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: '0x1' }], // chainId must be in hexadecimal numbers
                });
            this.LoadEthWallet();
        },
        async LoadEthWallet(){
            //const vueApp = this;
            await this.web3.eth.getAccounts()
            .then(acc=>{
                this.walletAddress = acc[0];
                sessionStorage.setItem("walletAddress", acc[0])
                this.web3.eth.getBalance(this.walletAddress)
                .then(balance=>{
                    this.walletBalance = this.web3.utils.fromWei(balance, "ether");  
                });
                //vueApp.LoadContract();
            })
            .catch(err=>{
                console.log(err);
            })       
            
        },
    }
}
</script>

<style>
.lines2
{
    position: absolute;
    top: 8%;
    left: 14%;
}
.connectButton
{
    border: solid 2px white;
    width: 7vw;
    font-size: 1vw;
    color: white;
    padding: 5px;
    position: absolute;
    top: 1%;
    left: 85.2%;
    cursor: pointer;
    transition: all .2s ease-in-out;
    z-index: 5;
}
.connectButton:hover
{
    transform: scale(0.9);
}
#NavBar
{
    border-bottom: none !important;
    background: transparent !important;
    margin-top: 1vh !important;
    z-index: 99 !important;
    height: 200px !important;
    position: absolute;
    top: 0%;
    left: 20%;
}
.activeBar
{
    opacity: 0;
}
.isActive
{
    opacity: 1;
}
.HoverOver
{
    display: inline-block;
    margin-right: 1vh;
    margin-left: 1vh;
}
.el-menu
{
    border-bottom: none !important;
    background: transparent !important;
    margin-top: 1vh !important;
    z-index: 2;
}
.homeNav
{
    font-size: 20px;
    display: block;
    font-weight: 600;
    letter-spacing: 0.2vh;
    margin-bottom: -0.5vh;
    color: rgba(0, 0, 0, 0.8) !important;
    cursor: pointer;

}


.HoverOver:hover
{
    background: transparent !important;
    color: rgb(80, 80, 80) !important;
    
}
.HoverOver:focus
{
    background: transparent !important;
    color: rgb(80, 80, 80) !important;
}
.el-divider--vertical
{
    height: 15px !important;
    margin-top: -3vh !important;
    border-left: 1px rgba(0, 0, 0, 0.445) solid !important;
}
.flex-grow {
  flex-grow: 0.3;
}
.flex-grow2 {
  flex-grow: 0.5;
}

.button-13 {
  background-color: transparent;
  border: 2px solid white;
  box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
  box-sizing: border-box;
  color: black;
  cursor: pointer;
  display: inline-block;
  font-family: "Nebula Regular" !important;
  font-size: 20px;
  line-height: 29px;
  width: 150px !important;
  padding: 0 10px 0 11px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}
.button-13:hover {
    background-color: #070707;
    color: #FAF9F6;
}
.grey
{
    color: grey !important;
    cursor: default !important;
}

</style>