<template>
<div class="bgContain">
  <Navbar/>
  <img class="lineGraphic" src="../../assets/HeroPage/lineGraphic.png" alt="">
  <img id="cyber" src="../../assets/HeroPage/cyber.png" alt="">
  <img id="cyberLines" src="../../assets/HeroPage/lines.png" alt="">
  <img class="infoGraphic" src="../../assets/HeroPage/constructionGraphic.png" alt="">
  <p class="infoGraphicText">Art <br> Powered by BASE</p>
  <img class="scrollDivIcon" src="../../assets/HeroPage/scrollDown.png" alt="">
  <h3 class="scrollDivText">Scroll Down</h3>
  <img id="hexagon1" src="../../assets/HeroPage/hexagon1.png" alt="">
  <img id="hexagon2" src="../../assets/HeroPage/hexagon2.png" alt="">
  <img id="branch1" src="../../assets/HeroPage/branch1.png" alt="">
  <img id="branch2" src="../../assets/HeroPage/branch2.png" alt="">
  <img id="branch3" src="../../assets/HeroPage/branch3.png" alt="">
  <img id="particles" src="../../assets/HeroPage/particles.png" alt="">

  <img id="bird" src="../../assets/HeroPage/bird.png" alt="">
  <img id="bird2" src="../../assets/HeroPage/bird2.png" alt="">
  <img id="bird3" src="../../assets/HeroPage/bird3.png" alt="">


  <h2 class="home">HOME</h2>
  <img class="logoHero" src="../../assets/HeroPage/logo.png" alt="">
  <p class="heroText"><strong>Project Cyber</strong> is an NFT collection of 10,000   
      unique characters from a dystopian future 

      where humanity, AI, and cyborgs fight for survival. 
      Join protagonist <strong>Jax</strong> and <strong>Nova</strong> on their quest

      for the truth behind the Genesis project.
      An epic story of love, ethics and power.
  </p>


  <!-- <img class="button1" src="../../assets/HeroPage/button1.png" alt=""> -->
  <p class="button2">COMING SOON</p>

    
</div>
    
</template>

<script>
import Navbar from '../Navigation/Navbar.vue'
export default {
    components:{
        Navbar
    },
    mounted()
    {
        window.addEventListener("scroll", this.scrollChanges)
        document.body.style.overflowY = "visible"
    },
    methods:{
        scrollChanges()
        {
            var checkpoint = 100;
            var checkpoint2 = 80;
            var checkpoint3 = 50;

            var cyber = 53 - (window.top.scrollY / checkpoint)
            var cyberLines = 63 - (window.top.scrollY / checkpoint)
            var branch1 = 42 + (window.top.scrollY / checkpoint2 * 2)
            var branch2 = 36 + (window.top.scrollY / checkpoint3 * 2)

            var branch3Left = 75 - (window.top.scrollY / checkpoint2 * 2)
            var branch3Top = 20 + (window.top.scrollY / checkpoint2 * 2)

            var bird1Left = 40 + (window.top.scrollY / checkpoint2 * 2)
            var bird1Top = 65 - (window.top.scrollY / checkpoint2 * 2)

            var bird2 = 45 + (window.top.scrollY / checkpoint2)
            var bird3 = 38 + (window.top.scrollY / checkpoint2 * 2)

            var hexagon1Top = 60 - (window.top.scrollY / checkpoint3 )
            var hexagon2Top = 36 - (window.top.scrollY / checkpoint3 )

            var hexagon1Left = 47 - (window.top.scrollY / checkpoint2 / 10)
            var hexagon2Left = 74 - (window.top.scrollY / checkpoint2 / 10)

            var particlesLeft = 30 + (window.top.scrollY / checkpoint2)



            document.getElementById("hexagon1").style.top = `${hexagon1Top}%`
            document.getElementById("hexagon2").style.top = `${hexagon2Top}%`
            
            document.getElementById("hexagon1").style.left = `${hexagon1Left}%`
            document.getElementById("hexagon2").style.left = `${hexagon2Left}%`

            document.getElementById("particles").style.left = `${particlesLeft}%`

            document.getElementById("cyber").style.left = `${cyber}%`
            document.getElementById("cyberLines").style.left = `${cyberLines}%`

            document.getElementById("branch1").style.left = `${branch1}%`
            document.getElementById("branch2").style.left = `${branch2}%`

            document.getElementById("branch3").style.top = `${branch3Top}%`
            document.getElementById("branch3").style.left = `${branch3Left}%`

            document.getElementById("bird2").style.left = `${bird2}%`
            document.getElementById("bird3").style.left = `${bird3}%`


            document.getElementById("bird").style.left = `${bird1Left}%`
            document.getElementById("bird").style.top = `${bird1Top}%`
        }
    }
}
</script>

<style>


.bgContain
{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: url('../../assets/HeroPage/heroBG.png') no-repeat center top;
  background-size: 100% 100%;
  min-width: 1250px !important;
  min-height: 800px !important;
  z-index: 1 !important;
}

.home
{
  position: absolute;
  display: flex;
  z-index: 2;
  top: 10%;
  left: 15%;
  background: black;
  border-radius: 10px;
  color: #efb917ec;
  text-align: center;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  -webkit-animation: slide-in-elliptic-top-fwd 1.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-elliptic-top-fwd 1.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  transition: all .2s ease-in-out;
}
.logoHero
{
  position: absolute;
  display: flex;
  z-index: 2;
  top: 20%;
  left: 15%;
  -webkit-animation: slide-in-elliptic-top-fwd 1.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-elliptic-top-fwd 1.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  transition: all .2s ease-in-out;
}
.heroText
{
  position: absolute;
  z-index: 2;
  top: 38%;
  left: 15%;
  line-height: 2;
  width: 35vw;
  font-size: 2vh;
  text-align: justify;
  -webkit-animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  transition: all .2s ease-in-out;
  font-family: 'Antonio', sans-serif;
}
#branch1
{
  position: absolute;
  display: flex;
  z-index: 2;
  width: 40vh;
  top: 56.5%;
  left: 42%;
}
#branch2
{
  position: absolute;
  display: flex;
  z-index: 2;
  width: 25vh;
  top: 77.5%;
  left: 36%;
}
#branch3
{
  position: absolute;
  display: flex;
  z-index: 2;
  width: 25vh;
  top: 20%;
  left: 75%;
}
.button1
{
  position: absolute;
  z-index: 2;
  top: 55%;
  left: 15%;
  width: 40vh;
  -webkit-animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  transition: all .2s ease-in-out;
  cursor: pointer;
}
.button1:hover
{
  transform: scale(0.9);
}
.button2
{
  position: absolute;
  z-index: 2;
  top: 55%;
  left: 15%;
  width: 15vh;
  font-size: 1.5vh;
  background: #efb917;
  padding: 10px;
  -webkit-animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  transition: all .2s ease-in-out;
  cursor: pointer;
}
.button2:hover
{
  transform: scale(0.9);
}
#hexagon1
{
  position: absolute;
  display: flex;
  z-index: 2;
  top: 60%;
  left: 47%;
  width: 5vh;
}
#hexagon2
{
  position: absolute;
  display: flex;
  z-index: 2;
  top: 36%;
  left: 74%;
  width: 60px;
}
.lineGraphic
{
  position: absolute;
  display: flex;
  z-index: 2;
  width: 200px;
  top: 83%;
  left: 8%;
  -webkit-animation: slide-in-elliptic-top-fwd 1.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-elliptic-top-fwd 1.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  transition: all .2s ease-in-out;
}

#cyber
{
  position: absolute;
  display: flex;
  z-index: 3;
  top: 0.4%;
  left: 53%;
  width: 64vh;
  -webkit-animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
#cyberLines
{
  position: absolute;
  display: flex;
  z-index: 3;
  top: 84%;
  left: 63%;
  width: 25vh;
  -webkit-animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.infoGraphicText
{
  position: absolute;
  display: flex;
  z-index: 2;
  top: 73%;
  left: 31.5%;
  text-align: justify;
  font-family: 'Antonio', sans-serif;
  font-weight: 500;
  font-size: 2vh;
  -webkit-animation: slide-in-elliptic-top-fwd 1.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-elliptic-top-fwd 1.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  transition: all .2s ease-in-out;
  
}
.infoGraphic
{
  position: absolute;
  display: flex;
  z-index: 2;
  width: 5vh;
  top: 75.5%;
  left: 28%;
  -webkit-animation: slide-in-elliptic-top-fwd 1.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-elliptic-top-fwd 1.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  transition: all .2s ease-in-out;
}
.scrollDivIcon
{
  position: absolute;
  display: flex;
  z-index: 2;
  width: 3vh;
  top: 87%;
  left: 25%;
  -webkit-animation: slide-in-elliptic-top-fwd 1.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both, vibrate-1 1s linear infinite both;
  animation: slide-in-elliptic-top-fwd 1.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both, vibrate-1 1s linear infinite both;
  transition: all .2s ease-in-out;
}
.scrollDivText
{
  position: absolute;
  display: flex;
  z-index: 2;
  width: 500px;
  top: 92%;
  left: 25%;
  font-size: 25px;
  font-weight: 500;
  -webkit-animation: slide-in-elliptic-top-fwd 1.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-elliptic-top-fwd 1.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  transition: all .2s ease-in-out;
}

#particles
{
  position: absolute;
  top: 10%;
  left: 30%;
  width: 100vh;
  z-index: 2;
}
#bird
{
  position: absolute;
  top: 65%;
  left: 40%;
  z-index: 2;
}
#bird2
{
  position: absolute;
  top: 20%;
  left: 45%;
  z-index: 2;
}
#bird3
{
  position: absolute;
  top: 10%;
  left: 38%;
  z-index: 2;
}

@media only screen and (max-width: 1280px) {
  .lineGraphic {
    width: 125px;
    top: 87%;
    left: 10%;
  }

  .scrollDivText
  {
    font-size: 20px;
  }
  .infoGraphicText
  {
    font-size: 16px;
    left: 31%;
  }
  .heroText
  {
    font-size: 15px;
    top: 40%;
  }

}


@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
            transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
            transform-origin: 50% 1400px;
    opacity: 1;
  }
}
@keyframes slide-in-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
            transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
            transform-origin: 50% 1400px;
    opacity: 1;
  }
}




</style>