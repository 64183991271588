<template>
<div>
  <Sidebar v-if="!isMobile()"/>
  <Hero v-if="!isMobile()"/>
  <Plasma v-if="!isMobile()"/>
  <Vs v-if="!isMobile()"/>
  <Vertical v-if="!isMobile()"/>


  <Mobile v-if="isMobile()"/>
</div>
</template>

<script>

import Sidebar from './Navigation/Sidebar.vue'
import Hero from './HomeComps/Hero.vue'
import Plasma from './HomeComps/Plasma.vue'
import Vs from './HomeComps/Vs.vue'
import Vertical from './HomeComps/Vertical.vue'

import Mobile from './Mobile.vue'

export default {
  components:{
    Sidebar,
    Hero,
    Plasma,
    Vs,
    Vertical,
    Mobile
  },
  mounted()
  {
    window.scrollTo(0,0)
  },
  methods: {
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>

</style>