<template>

    <video class="verticalVideoMobile" autoplay muted loop playsinline >
        <source src="../assets/VerticalPage/vertical.mp4" type="video/mp4">
    </video>

    <img class="mobileLogo" src="../assets/HeroPage/logoWhite.png" alt="">
    <h2 class="mobileButton">SOON</h2>

    <img @click="goTo('https://twitter.com/projectcybernft')" class="twitterMobile" src="../assets/SideBar/twitter.svg" alt="">

    <h4 class="mobileInfo">Mobile website coming soon.<br><br>For the full experience <br>visit our desktop version</h4>

</template>

<script>
export default {
    mounted()
    {
        if(this.isMobile())
        {
            document.getElementById("htmlFull").style.overflowX = "hidden"
            document.body.style.overflowX = "hidden"
            document.body.style.position = "relative"
        }
    },
    methods:{
        goTo(link)
        {
            window.open(
                link,
                '_blank' // <- This is what makes it open in a new window.
            );
        },
        routeTo(location)
        {
            this.$router.push({ name: location})
        },
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    }
}
</script>

<style>
.verticalVideoMobile
{
    position: relative;
    width: 100%;
}
.mobileLogo
{
    position: absolute;
    top: 15vh;
    left: 20%;
    width: 60vw;
    transform: translate(-50% -50%);
}
.twitterMobile,.discordMobile
{
    position: absolute;
    z-index: 5;
    width: 5vh;
    left: 15vw;
    top: 5%;
    filter: invert(100%) sepia(0%) saturate(7484%) hue-rotate(240deg) brightness(111%) contrast(99%);
}
.discordMobile
{
    left: 72vw;
}
.mobileInfo
{
    position: absolute;
    transform: translate(-50% -50%);
    top: 68vh;
    left: 0%;
    color: white;
    width: 100%;
    text-align: center;
    font-size: 1.5vh;
    font-family: 'Antonio', sans-serif;
}

.mobileButton
{
    position: absolute;
    top: 33vh;
    left: 39%;
    width: 20vw;
    background: #000;
    border-radius: 10px;
    color: rgba(239,185,23,.9254901960784314);
    text-align: center;
    padding-left: 10px;
    padding-right: 7px;
    padding-top: 2px;
    padding-bottom: 2px;
}

</style>