import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home.vue'
//import About from '../components/About.vue'
import Gallery from '../components/Gallery.vue'
//import MintManager from '../components/MintComps/MintManager.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/About',
  //   name: 'About',
  //   component: About
  // },
  {
    path: '/Gallery',
    name: 'Gallery',
    component: Gallery
  },
  // {
  //   path: '/Mint',
  //   name: 'Mint',
  //   component: MintManager
  // },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
