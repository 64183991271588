<template>
<div>
    <el-menu
        class="el-menu-vertical-cyber"
        :collapse="false"
    >
        <div style="margin-top: 10vh"></div>
        <el-menu-item class="logo sideMenu" index="2" @click="goUp()">
            <img class="logoImage" src="../../assets/logo.png" alt="">
        </el-menu-item>
        
        <div style="margin-top: 10vh"></div>
        <img src="../../assets/SideBar/icon.png" alt="">
        <div style="margin-top: 4vh"></div>

        <h4 class="sideText">Project Cyber <br><span class="smallTxt">ART x BASE</span></h4>

        <div style="margin-top: 4vh"></div>
        <img src="../../assets/SideBar/icon.png" alt="">

        <div style="margin-top: 10vh"></div>
        <img @click="goTo('https://twitter.com/projectcybernft')" class="twitterSide" src="../../assets/SideBar/twitter.svg" alt="">
        <!-- <div style="margin-top: 2vh"></div>
        <img @click="goTo('https://discord.com/invite/projectcybernft')" class="discordSide" src="../../assets/SideBar/discord.svg" alt=""> -->
        <div style="margin-top: 3vh"></div>
        <img @click="togglePlay()" class="burger" v-if="!isPlayingNav" src="../../assets/mp3/play.svg" alt="">

        <svg  id="equalizer" @click="togglePlay()" v-if="isPlayingNav" viewBox="0 0 10 7" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g fill="#000000">
            <rect id="bar1" transform="translate(0.500000, 6.000000) rotate(180.000000) translate(-0.500000, -6.000000) " x="0" y="5" width="1" height="2px"></rect>
            <rect id="bar2" transform="translate(3.500000, 4.500000) rotate(180.000000) translate(-3.500000, -4.500000) " x="3" y="2" width="1" height="5"></rect>
            <rect id="bar3" transform="translate(6.500000, 3.500000) rotate(180.000000) translate(-6.500000, -3.500000) " x="6" y="0" width="1" height="7"></rect>
            <rect id="bar4" transform="translate(9.500000, 5.000000) rotate(180.000000) translate(-9.500000, -5.000000) " x="9" y="3" width="1" height="4"></rect>
        </g>
        </svg>
    </el-menu>
</div>
</template>

<script>
export default {
    data()
    {
        return{
            audio: new Audio(require("../../assets/mp3/desolate.mp3")),
            isPlayingNav: false
        }
    },
    mounted()
    {
        if(localStorage.getItem("isPlaying") === "true" )
        {
            this.isPlayingNav =  true;
        } 
        else
        {
            this.isPlayingNav =  false;
        }

    },
    methods:{
        togglePlay() {
          this.isPlayingNav ? this.audio.pause() : this.audio.play();
                this.audio.loop = true;

                this.isPlayingNav = !this.isPlayingNav;
                this.audio.volume = 0.35;
                
                localStorage.setItem("isPlaying", this.isPlayingNav);
          },
          goUp()
          {
            if(this.$route.name == "Home")
            {
              window.scrollTo(0,0)
            }
            else
            {
              this.$router.push({ name: "Home"})
            }
          },
        goTo(link)
        {
            window.open(
                link,
                '_blank' // <- This is what makes it open in a new window.
            );
        },
        routeTo(location)
        {
            this.$router.push({ name: location})
        },
    }
}
</script>

<style>
.twitterSide, .discordSide
{
    width: 50%;
    transition: 0.2s all ease-in-out;
}
.burger
{
    width: 50%;
    transition: 0.2s all ease-in-out;
}
.burger:hover,.twitterSide:hover, .discordSide:hover
{
    transform: scale(0.9);
    cursor: pointer;
}
.sideMenu:hover
{
    background: transparent !important;
    color: rgb(156, 156, 156) !important;
}
.sideMenu.is-active
{
    color: black !important;
}
.el-menu-vertical-cyber:not(.el-menu--collapse) {
    width: 10vh;
    height: 1000vh;
    position: fixed !important;
    transform: translate(-50,-50%);
    top: -1%;
    background: #efb9179c !important;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0 !important;
}
.sideText
{
    writing-mode: vertical-rl;
    text-orientation: sideways;
    transform: rotate(180deg) !important;
    font-size: 2vh;
}
.smallTxt
{
    color: rgba(59, 59, 59, 0.753);
    font-size: 1.25vh; 
}
.logo
{
    color: black;
    font-size: 4vh;
}
.logoImage
{
    width: 100%;
}

#equalizer {
  width: 40%;
  cursor: pointer;
}

#bar1 {
  animation: bar1 1.2s infinite linear;
}

#bar2 {
  animation: bar2 0.8s infinite linear;
}

#bar3 {
  animation: bar3 1s infinite linear;
}

#bar4 {
  animation: bar4 0.7s infinite linear;
}

@keyframes bar1 {
  0% {
    height: 2px;
  }
  50% {
    height: 7px;
  }
  100% {
    height: 2px;
  }
}

@keyframes bar2 {
  0% {
    height: 5px;
  }
  40% {
    height: 1px;
  }
  80% {
    height: 7px;
  }
  100% {
    height: 5px;
  }
}

@keyframes bar3 {
  0% {
    height: 7px;
  }
  50% {
    height: 0;
  }
  100% {
    height: 7px;
  }
}

@keyframes bar4 {
  0% {
    height: 2px;
  }
  50% {
    height: 7px;
  }
  100% {
    height: 2px;
  }
}
</style>