<template>
  <router-view/>

</template>

<script>
//import preloader from './components/HomeComps/Loader.vue'
export default {
  name: "App",
  mounted()
  {
    localStorage.setItem("isPlaying", false);
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@400;700&display=swap');
@font-face {
  font-family: "Nebula Hollow";
  src: local("Nebula Hollow"),
   url('./assets/font/Nebula-Hollow.otf') format("opentype");
}
@font-face {
  font-family: "Nebula Regular";
  src: local("Nebula Regular"),
   url('./assets/font/Nebula-Regular.otf') format("opentype");
}
#app {
  font-family: "Nebula Regular"!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  margin: 0;
  
}
html {
  scroll-behavior: smooth !important;
}
html,body
{
  height: 100%;
  width: 100%;
  background: black;
  margin: 0;
}
body
{
  overflow-x: hidden !important;
}

</style>
