<template>
<div class="vsContain">
    <video class="vsVideo" autoplay loop playsinline muted >
        <source src="../../assets/VsPage/mirror.webm" type="video/webm">
    </video>

    <img class="vsV" src="../../assets/VsPage/V.png" alt="">
    <img class="vsS" src="../../assets/VsPage/S.png" alt="">

    <p class="vsTextOne">The Civil War</p>
    <img class="blackArrow" src="../../assets/VsPage/blackArrow.png" alt="">
    <img class="yellowArrow" src="../../assets/VsPage/yellowArrow.png" alt="">

    <p class="vsTextTwo">Humanity and Machine at War: Which side will you fight with? </p>

    <img class="CYB" src="../../assets/VsPage/CYB.png" alt="">
    <img class="ORG" src="../../assets/VsPage/ORG.png" alt=""> 
    <img class="HUM" src="../../assets/VsPage/HUM.png" alt=""> 
    <img class="ANS" src="../../assets/VsPage/ANS.png" alt="">  


    <img class="vsTextThreeBg" src="../../assets/VsPage/textBg3.png" alt="">
    <p class="vsTextThree">
        The precious asset of a corporation; A cyborg(Nova) born from a cybernetic miracle and a teenage criminal hacker(Jax) create a powerful bond that will be the bulwark of humanity in the fight against sinister dark revolutionary and government forces that are torn between wiping humans off the face of the earth or turn the world into a hive mind.
        <br><br>
        No matter the era, the technology, what or who it is up against, love is the most powerful force in the universe
    </p>

    <div class="cyborgBottom">
        <div class="firstSection">
            <p class="topText">Mind</p>
            <el-divider class="lineDivider" />
            <p class="bottomText">Cyborgs: An Artificial Mind</p>
        </div>
        <img class="bottomIcon" src="../../assets/VsPage/cyborgBrain.png" alt="">


        <div class="secondSection">
            <p class="topText">Senses</p>
            <el-divider class="lineDivider" />
            <p class="bottomText">Cyborg: vision exceeds human limits</p>
        </div>
        <img class="bottomIcon" src="../../assets/VsPage/cyborgEye.png" alt="">

        <div class="secondSection">
            <p class="topText">Strength</p>
            <el-divider class="lineDivider" />
            <p class="bottomText">Cyborgs: cybernetic gear</p>
        </div>
        <img class="bottomIcon" src="../../assets/VsPage/cyborgArm.png" alt="">
        
    </div>
    <div class="humanBottom">
        <img class="bottomIconRight" src="../../assets/VsPage/humanArm.png" alt="">
        <div class="firstSectionRight">
            <p class="topText">Strength</p>
            <el-divider class="lineDivider" />
            <p class="bottomText">The Human spirit endures</p>
        </div>
        


        <img class="bottomIconRight" src="../../assets/VsPage/humanEye.png" alt="">
        <div class="secondSectionRight">
            <p class="topText">Senses</p>
            <el-divider class="lineDivider" />
            <p class="bottomText">Humans embody unbeatable intuition</p>
        </div>
        


        <img class="bottomIconRight" src="../../assets/VsPage/humanBrain.png" alt="">
        <div class="secondSectionRight">
            <p class="topText">MIND</p>
            <el-divider class="lineDivider" />
            <p class="bottomText">Humans lead with logic</p>
        </div>
        
    </div>


</div>
</template>

<script>
export default {

}
</script>

<style>
.vsContain
{
  position: absolute;
  top: 200%;
  left:0;
  width: 100%;
  height: 100%;
  min-width: 1250px !important;
  min-height: 800px !important;
  z-index: 1 !important;
}
.vsVideo
{
    position: absolute;
    z-index: 3 !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    min-width: 100%; 
    min-height: 100%; 

    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;
    
}
.vsV
{
    z-index: 3 !important;
    position: absolute;
    width: 4vw;
    left: 45.5%;
    top: 53%;
}
.vsS
{
    z-index: 3 !important;
    position: absolute;
    width: 4vw;
    left: 50.5%;
    top: 53%;
}
.vsTextOne
{
    z-index: 3 !important;
    position: absolute;
    padding-top: 3px;
    padding-bottom: 3px;
    width: 7vw;
    font-size: 1.5vh;
    left: 46.5%;
    top: 20%;
    font-family: 'Antonio', sans-serif;
    color: black;
    border-radius: 5px;
    background: url('../../assets/VsPage/textBg1.png');
    background-repeat: no-repeat;
    background-size: 52%;
}
.blackArrow
{
    z-index: 3 !important;
    position: absolute;
    width: 2vw;
    left: 49.8%;
    top: 25%;
}
.yellowArrow
{
    z-index: 3 !important;
    position: absolute;
    width: 2vw;
    left: 48.1%;
    top: 25%;
}
.vsTextTwo
{
    z-index: 3 !important;
    font-family: 'Antonio', sans-serif;
    position: absolute;
    width: 40vh;
    font-size: 1.5vh;
    left: 39%;
    top: 30%;
    background: #351b29;
    color: black;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    background: url('../../assets/VsPage/textBg2.png');
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: left;
}
.CYB
{
    z-index: 3 !important;
    position: absolute;
    width: 13vw;
    left: 36.5%;
    top: 44%;
}
.ORG
{
    z-index: 3 !important;
    position: absolute;
    width: 13vw;
    left: 50.5%;
    top: 44%;
}
.HUM
{
    z-index: 3 !important;
    position: absolute;
    width: 13vw;
    left: 36.5%;
    top: 62%;
}
.ANS
{
    z-index: 3 !important;
    position: absolute;
    width: 13vw;
    left: 50.5%;
    top: 62%;
}

.vsTextThree
{
    z-index: 4 !important;
    font-family: 'Antonio', sans-serif;
    position: absolute;
    width: 32%;
    font-size: 1.5vh;
    padding: 20px;
    padding-right: 55px;
    color: #f5f6ed;
    left: 33%;
    top: 70%;
    text-align: justify;
    background: url('../../assets/VsPage/textBg3.png');
    background-size: 60% 100%;
    background-repeat: no-repeat;
    background-position: right;
}

.zero
{
    z-index: 3 !important;
    position: absolute;
    width: 1.75vw;
    left: 47.8%;
    top: 85%;
}
.three
{
    z-index: 3 !important;
    position: absolute;
    width: 2vw;
    left: 50.5%;
    top: 85.2%;
}
.cyborgBottom
{
    z-index: 4 !important;
    width: 50% !important;
    height: auto;
    position: absolute;
    top: 100% !important;
    left: 0%;
    background: url('../../assets/VsPage/cyborgBottomBg.png');
    background-size: cover;
    background-repeat: no-repeat; 
}
.humanBottom
{
    z-index: 4 !important;
    width: 50% !important;
    height: auto;
    position: absolute;
    top: 100%;
    left: 50%;
    background: url('../../assets/VsPage/humanBottomBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.lineDivider
{
    border-top: 1px solid black !important;
    margin-top: -1vh !important;
    margin-bottom: -1vh !important;
    width: 70% !important; 
    margin: auto !important;
    margin-top: -1vh !important;
    margin-bottom: -1vh !important;
}
.firstSection
{
    width: 10vw;
    margin-left: 12vh;
    display: inline-block;
    margin-right: -0.5vw;
}
.firstSectionRight
{
    width: 10vw;
    display: inline-block;
}
.secondSection
{
    width: 10vw;
    display: inline-block;
    margin-right: -0.5vw;
}
.secondSectionRight
{
    width: 10vw;
    display: inline-block;
}
.topText
{
    font-size: 0.8vw;
    margin-top: 2vh !important;
}
.bottomText
{
    font-family: 'Antonio', sans-serif;
    font-size: 0.7vw;
    margin-bottom: 2vh;
    text-transform: uppercase;
}
.bottomIcon
{
    display: inline-block;
    width: 1.5vw;
    margin-right: 3vh;
    margin-bottom: 0.7vw;
}
.bottomIconRight
{
    display: inline-block;
    width: 1.5vw;
    margin-left: 3vh;
    margin-right: -1vh;
    margin-bottom: 0.7vw;
}

@media only screen and (max-width: 1920px), screen and (max-height: 1000px)
{
    .rightArrow
    {
        left: 47%;
    }
    .vsTextTwo
    {
        background-size: 52%;
    } 
    
}
@media only screen and (max-width: 2000px), screen and (max-height:1200px) 
{
    .leftArrow
    {
        left: 10%;
    }
    .rightArrow
    {
        left: 45.5%;
    }
    .vsTextTwo
    {
        background-size: 11.5vw;
    } 
}
@media screen and (max-height: 1200px) {
    .vsContain
    {
        top: 208%;
    }
}
@media only screen and (max-width: 1500px) {
    .vsContain
    {
        top: 212%;
    } 
    .vsTextTwo
    {
        left: 37.5%;
    }
}

@media only screen and (max-width: 1300px) {
    .vsTextTwo
    {
        left: 35.5%;
    }
}

@media screen and (max-height: 800px) {
  .vsContain
    {
        top: 218%;
    } 
    .vsTextTwo
    {
        left: 37.5%;
    }

}

</style>