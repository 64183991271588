<template>
<div class="verticalContain">
    <video class="verticalVideo" autoplay loop playsinline muted >
        <source src="../../assets/VerticalPage/vertical.webm" type="video/webm">
    </video>

    <img class="topDots" src="../../assets/VerticalPage/topDots.png" alt="">

    <img class="after" src="../../assets/VerticalPage/post.png" alt="">
    <img class="match" src="../../assets/VerticalPage/battle.png" alt="">

    <p class="topLeftBox">
        <span class="title">GENESIS</span> 
        <br><br>
        In 2085, AIs are integrated into physical bodies called CroCNi using Plasma. 
        The Cyber Corporation is conducting "Genesis", a controversial project to generate an alternative to Plasma. 
        The press alleges the project's intention is to create a single hive mind and control the world. 
        "The Reaper" heads an international criminal organization called "Batch 64" that seeks to eliminate restrictions and harm humans.
    </p>

    <img class="slash" src="../../assets/VerticalPage/slash.png" alt="">


    <img class="city" src="../../assets/VerticalPage/city.png" alt="">
    <img class="bottomLeftBanner" src="../../assets/VerticalPage/banner.png" alt="">
    <p class="bottomLeftBox">
        <span class="title">Jax - Human</span> 
        <br><br>
        Jax, a 19 year-old hacker, is forced to join a cybernetic anti-terrorist group created by the Cyber Corporation to protect the valuable asset of their "Genesis" project, Nova. 
        As Jax and Nova navigate the conflict between dark forces, they develop a love that challenges the status quo and demonstrates to the world that inter-species love is the key to a new humanity. 
        Despite the challenges they face, their love endures as they fight to protect each other and bring about a brighter future.
    </p>

    <p class="bottomRightBox">
        <span class="title">Nova - Cyborg</span> 
        <br><br>
        The "Genesis" project's most precious asset is a self-absorbed and shy cyborg named Nova. 
        She was born spontaneously 5 years prior in a plasma-filled capsule within the Cyber Corporation facilities. 
        Nova has blue skin, golden circuitry decorating her face, chest, arms, and legs, and the appearance of an 18-year-old teenager. 
        Despite her human-like appearance, she has all the attributes of a cyborg, including hyper-intelligence and the ability to create plasma with her mind. 
        Jax falls in love with Nova's innocence and beauty, and they become caught up in a conflict between global dark forces. 
        Through their relationship, Jax and Nova demonstrate that love between species is the beginning of a new humanity.
    </p>



    <img class="footerLogo" src="../../assets/VerticalPage/logo.png" alt="">
    <h2 class="copyright">© copyright project cyber 2024 all rights reserved</h2>

<!--     <h2 class="joinUs">JOIN US</h2>
    <img @click="goTo('https://twitter.com/projectcybernft')" class="twitterFooter" src="../../assets/VerticalPage/twitter.svg" alt="">
    <img @click="goTo('https://discord.com/invite/projectcybernft')" class="discordFooter" src="../../assets/VerticalPage/discord.svg" alt="">


    <h2 class="contactText">contact</h2>
    <h2 class="contactEmail">Info@NFTuner.io</h2> -->
</div>
</template>

<script>
export default {
    methods:
    {
        goTo(link)
        {
            window.open(
                link,
                '_blank' // <- This is what makes it open in a new window.
            );
        }
    }
}
</script>

<style>
.verticalContain
{
  position: absolute;
  top: 308%;
  left:0;
  width: 100%;
  height: 100%;
  min-width: 1250px !important;
  min-height: 800px !important;
  z-index: 1 !important;
}
.verticalVideo
{
    position: absolute;
    z-index: 3 !important;
    left: 0% !important;
    width: 100%;
    min-width: 1250px !important;
  min-height: 800px !important;
    top: 5%;
}

.topDots
{
    position: absolute;
    top: 28%;
    left: 16%;
    width: 67vw;
    z-index: 4 !important;
}
.after
{
    position: absolute;
    z-index: 4 !important;
    top: 50%;
    left: 32%;
    width: 60vh;
}
.match
{
    position: absolute;
    z-index: 4 !important;
    top: 65%;
    left: 32%;
    width: 80vh;
}

.title
{
    font-size: 1.5vw !important;
    font-family: "Nebula Regular"!important;
    text-align: justify;
}
.topLeftBox
{
    position: absolute;
    z-index: 4;
    width: 25vw;
    left: 15%;
    top: 75%;
    font-size: 0.8vw;
    color: white;
    text-align: justify;
    background: url('../../assets/VerticalPage/dottedBorder.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 60px;
    font-family: 'Antonio', sans-serif;
}
.slash
{
    position: absolute;
    z-index: 4;
    top: 92%;
    left: 65%;
    width: 8vw;
}
.city
{
    position: absolute;
    z-index: 4;
    left: 19.2%;
    top: 111%;
    width: 20vw;
}
.bottomLeftBox
{
    position: absolute;
    z-index: 4;
    left: 18%;
    top: 133%;
    width: 20vw;
    font-size: 0.8vw;
    color: white;
    text-align: justify;
    font-family: 'Antonio', sans-serif;
}
.bottomLeftBanner
{
    position: absolute;
    z-index: 4;
    width: 25vw;
    height: 26vw;
    left: 15%;
    top: 112%;

}
.bottomRightBox
{
    position: absolute;
    z-index: 4;
    width: 15vw;
    left: 58%;
    top: 110%;
    font-size: 0.8vw;
    color: white;
    text-align: justify;
    background: url('../../assets/VerticalPage/opacityBorder.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 80px;
    font-family: 'Antonio', sans-serif;
}
.copyright
{
    color: white;
    position: absolute;
    z-index: 4;
    top: 290%;
    left: 26%;
    font-weight: 500;
}
.footerLogo
{
    position: absolute;
    z-index: 4;
    top: 270%;
    left: 38%;
    width: 45vh;
}

.joinUs
{
    position: absolute;
    z-index: 4;
    top: 270%;
    left: 85%;
    color: white;
    font-weight: 500;
}
.twitterFooter
{
    position: absolute;
    z-index: 4;
    top: 278%;
    left: 86.5%;
    width: 5vh;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
}
.discordFooter
{
    position: absolute;
    z-index: 4;
    top: 285%;
    left: 86.5%;
    width: 5vh;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
}

.twitterFooter:hover, .discordFooter:hover
{
    transform: scale(0.9);
}

.contactText
{
    position: absolute;
    z-index: 4;
    top: 270%;
    left: 11%;
    color: white;
    font-weight: 500;
}
.contactEmail
{
    position: absolute;
    z-index: 4;
    top: 276%;
    left: 11%;
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-family: 'Antonio', sans-serif;
}

@media only screen and (max-width: 4000px) {
    .after
    {
        width: 40vh;
        top: 40%;
        left: 45%;
    }
    .match
    {
        width: 60vh;
        top: 50%;
        left: 35%;
    }

    .copyright
    {
        color: white;
        position: absolute;
        z-index: 4;
        top: 337%;
        left: 35%;
        font-weight: 500;
    }
    .footerLogo
    {
        position: absolute;
        z-index: 4;
        top: 320%;
        left: 38%;
    }
    .joinUs
    {
        top: 320%;
    }
    .twitterFooter
    {
        top: 330%;
    }
    .discordFooter
    {
        top: 337%;
    }

    .contactText
    {
        top: 320%;
    }
    .contactEmail
    {
        top: 330%;
    }
}
@media only screen and (max-width: 2000px), screen and (max-height:1200px) {
    .verticalVideo
    {
        top: 0%;
    }
    .topDots
    {
        top: 18%;
    }

    .after
    {
        width: 40vh;
        top: 34%;
        left: 40%;
    }
    .match
    {
        width: 60vh;
        top: 45%;
        left: 35%;
    }
    .topLeftBox
    {
        top: 58%;
    }

    .copyright
    {
        color: white;
        position: absolute;
        z-index: 4;
        top: 290%;
        left: 26%;
        font-weight: 500;
    }
    .footerLogo
    {
        position: absolute;
        z-index: 4;
        top: 270%;
        left: 38%;
    }
    .joinUs
    {
        top: 270%;
    }
    .twitterFooter
    {
        top: 277%;
    }
    .discordFooter
    {
        top: 283%;
    }

    .contactText
    {
        top: 270%;
    }
    .contactEmail
    {
        top: 277%;
    }
    
}

@media only screen and (max-height: 1200px)
{
    .verticalVideo
    {
        top: 9%;
    }
    .topDots
    {
        top: 28%;
    }

    .after
    {
        width: 50vh;
        top: 45%;
        left: 39%;
    }
    .match
    {
        width: 70vh;
        top: 55%;
        left: 35%;
    }

    .topLeftBox
    {
        top: 75%;
    }

    .slash
    {
        top: 118%;
    }

    .bottomRightBox
    {
        top: 133%;
    }

    .copyright
    {
        color: white;
        position: absolute;
        z-index: 4;
        top: 340%;
        left: 30%;
        font-weight: 500;
    }
    .footerLogo
    {
        position: absolute;
        z-index: 4;
        top: 320%;
        left: 38%;
    }

    .joinUs
    {
        top: 320%;
    }
    .twitterFooter
    {
        top: 328%;
    }
    .discordFooter
    {
        top: 335%;
    }

    .contactText
    {
        top: 320%;
    }
    .contactEmail
    {
        top: 326%;
    }

    .city
    {
        left: 19.2%;
        top: 135%;
    }
    .bottomLeftBox
    {
        left: 19.5%;
        width: 19vw;
        top: 159%;
    }
    .bottomLeftBanner
    {
        left: 15%;
        top: 136%;
    }
}

@media only screen and (max-width: 1500px) {
    .verticalVideo
    {
        top: 7.5% !important;
    }
    .topDots
    {
        top: 24%;
    }
    
    .topLeftBox
    {
        top: 60%;
    }

    .slash
    {
        top: 92%;
    }
    .bottomRightBox
    {
        top: 104%;
        padding: 50px;
    }
    .copyright
    {
        color: white;
        position: absolute;
        z-index: 4;
        top: 265%;
        left: 30%;
        font-weight: 500;
        font-size: 2vh;
    }
    .footerLogo
    {
        position: absolute;
        z-index: 4;
        top: 245%;
        left: 40%;
    }
    .joinUs
    {
        top: 245%;
    }
    .twitterFooter
    {
        top: 253%;
        left: 87.5%;
    }
    .discordFooter
    {
        top: 260%;
        left: 87.5%;
    }
    .contactText
    {
        top: 245%;
    }
    .contactEmail
    {
        top: 252%;
    }

    .city
    {
        left: 19.2%;
        top: 105%;
    }
    .bottomLeftBox
    {
        left: 18.5%;
        top: 125%;
    }
    .bottomLeftBanner
    {
        left: 15%;
        top: 105%;
        height: 28vw;
    }
}
@media screen and (max-width: 1450px) {
    .verticalVideo
    {
        top: 8.5% !important;
    }
    .topDots
    {
        top: 23%;
    }

    .after
    {
        width: 40vh;
        top: 35%;
        left: 38%;
    }
    .match
    {
        width: 60vh;
        top: 45%;
        left: 32%;
    }
    .topLeftBox
    {
        top: 50%;
    }
    .slash
    {
        top: 92%;
    }
    .bottomRightBox
    {
        top: 104%;
    }
}
@media screen and (max-width: 1300px) {
    .verticalVideo
    {
        top: 7% !important;
    }
    .topDots
    {
        top: 20%;
    }

    .after
    {
        width: 40vh;
        top: 30%;
        left: 36%;
    }
    .match
    {
        width: 60vh;
        top: 38%;
        left: 30%;
    }
    .topLeftBox
    {
        top: 50%;
    }
    .slash
    {
        top: 78%;
    }

    .bottomRightBox
    {
        top: 88%;
        left: 58%;
    }
    .copyright
    {
        color: white;
        position: absolute;
        z-index: 4;
        top: 225%;
        left: 35%;
        font-weight: 500;
        font-size: 1.5vh;
    }
    .footerLogo
    {
        position: absolute;
        z-index: 4;
        top: 210%;
        left: 43%;
    }
    .joinUs
    {
        top: 210%;
    }
    .twitterFooter
    {
        top: 218%;
        left: 87.5%;
    }
    .discordFooter
    {
        top: 225%;
        left: 87.5%;
    }

    .contactText
    {
        top: 210%;
    }
    .contactEmail
    {
        top: 217%;
    }    

    .city
    {
        left: 19.2%;
        top: 88%;
    }
    .bottomLeftBox
    {
        top: 105%;
    }
    .bottomLeftBanner
    {
        left: 15%;
        top: 88%;
        height: 28vw;
    }
}
@media screen and (max-height: 800px) {
    .verticalVideo
    {
        top: 17% !important;
    }
    .topDots
    {
        top: 32%;
    }

    .after
    {
        width: 40vh;
        top: 42%;
        left: 39%;
    }
    .match
    {
        width: 60vh;
        top: 52%;
        left: 33%;
    }

    .topLeftBox
    {
        top: 65%;
    }

    .slash
    {
        top: 100%;
    }

    .bottomRightBox
    {
        top: 112%;
        left: 58%;
    }
    .copyright
    {
        color: white;
        position: absolute;
        z-index: 4;
        top: 275%;
        left: 35%;
        font-weight: 500;
        font-size: 1.5vh;
    }
    .footerLogo
    {
        position: absolute;
        z-index: 4;
        top: 258%;
        left: 40%;
    }
    .joinUs
    {
        top: 258%;
    }
    .twitterFooter
    {
        top: 266%;
        left: 88%;
    }
    .discordFooter
    {
        top: 273%;
        left: 88%;
    }

    .contactText
    {
        top: 258%;
    }
    .contactEmail
    {
        top: 265%;
    }     


    .city
    {
        left: 19.2%;
        top: 110%;
    }
    .bottomLeftBox
    {
        top: 130%;
    }
    .bottomLeftBanner
    {
        left: 15%;
        top: 110%;
        height: 28vw;
    }
}
</style>